<template>
  <div v-if="$store.state.is_godfather">
    <portal to="pageHeaderRight">
      <cancel-button @cancel="cancelEdit"></cancel-button>
      <submit-button :isSaving="isSaving"/>
    </portal>
    <div id="pageEdit__container">
      <form @submit.prevent="formSubmit" @keydown.enter.prevent.self>
        <input type="submit" id="submitForm" hidden=""/>
        <div class="grid-x grid-margin-x">
          <div
            class="cell page__inner-content"
            :class="[
              $route.params.pathName == 'dashboard' ? 'medium-12' : 'medium-9'
            ]"
          >
            <edit-content-repeater
              v-bind:contentItems.sync="brandData.value"
              :dropzoneReady="dropzoneReady"
              :dragOptions="dragOptions"
              :isMainContent="true"
              @remove="removeValueBlock"
              @add-item="openBrandData"
            ></edit-content-repeater>
            <add-brand-data-button
              v-if="brandData.value.length == 0"
              @add-item="openBrandData(0, true)"
            />
          </div>
          <div
            class="cell medium-3"
            v-if="$route.params.pathName != 'dashboard'"
          >
            <aside>
              <edit-content-repeater
                v-bind:contentItems.sync="brandData.sidebar"
                :dropzoneReady="dropzoneReady"
                :dragOptions="dragOptions"
                :isMainContent="false"
                @remove="removeSidebarBlock"
                @add-item="openBrandData"
              ></edit-content-repeater>
              <add-brand-data-button
                v-if="brandData.sidebar.length == 0"
                @add-item="openBrandData(0, false)"
              />
            </aside>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-else>
    <h3>Unauthorized</h3>
  </div>
</template>

<script>
import backend from '@/backend'
import { dirtyRoute } from '@/views/common/mixins/routes/dirtyRoute'
import addBrandData from '../components/edit/elements/modal/addBrandData'
import EditContentRepeater from '@/views/brand/components/EditContentRepeater'

export default {
  name: 'BrandDescribeEdit',
  mixins: [dirtyRoute],
  data () {
    return {
      brandData: {
        value: [],
        sidebar: []
      },
      title: '',
      slug: '',
      dropzoneReady: false,
      drag: false,
      isSaving: false
    }
  },
  created () {
    backend
      .get(
        `${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}/describe`
      )
      .then(response => {
        this.brandData.value = response.data.value
        this.brandData.sidebar = response.data.sidebar
        this.title = response.data.name
        this.slug = response.data.slug
        this.dropzoneReady = true

        for (let i = 0; i < this.brandData.value.length; i++) {
          this.brandData.value[i].uid = i
        }
        for (let i = 0; i < this.brandData.sidebar.length; i++) {
          this.brandData.sidebar[i].uid = i
        }
        this.$nextTick(() => {
          this.dirty = false
        })
      })

    this.$setupKeypress('s', this.formSubmit)
  },
  computed: {
    dragOptions () {
      return {
        scrollSensitivity: 200,
        forceFallback: true
      }
    }
  },
  methods: {
    returnListItem () {
      if (this.$route.params.pathName === 'dashboard') {
        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({
          name: 'BrandDescribe',
          params: { pathName: this.$route.params.pathName }
        })
      }
    },
    cancelEdit () {
      this.$router.push({
        name: 'BrandDescribe',
        params: { pathName: this.$route.params.pathName }
      })
    },
    formSubmit () {
      this.isSaving = true
      this.dirty = false
      const vm = this
      const keys = ['sidebar', 'value']
      keys.forEach(key => {
        const data = vm.brandData[key]
        data.forEach(dataItem => {
          delete dataItem.component
          delete dataItem.id
        })
      })

      backend
        .post(
          `${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}/describe`,
          {
            items: this.brandData
          }
        )
        .then(() => {
          const url = this.$route.path.replace('/edit', '')
          this.$router.push(url)
        })
        .catch(() => {
          this.isSaving = false
        })
    },
    openBrandData (index, type) {
      this.$modal.show(
        addBrandData,
        {
          id: index,
          brandData: this.brandData,
          type: type
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '860px'
        }
      )
    },
    hide (id) {
      this.$modal.hide('modal' + id)
    },
    removeValueBlock (index) {
      this.brandData.value.splice(index, 1)
    },
    removeSidebarBlock (index) {
      this.brandData.sidebar.splice(index, 1)
    }
  },
  watch: {
    brandData: {
      handler (newVal, oldVal) {
        this.dirty = true
      },
      deep: true
    }
  },
  components: {
    EditContentRepeater
  }
}
</script>
